import { Anniversary } from "./anniversary.service";
import { Assignement } from "./assignement/assignement.service";
import { Formation } from "./assignement/formation.service";
import { Project } from "./assignement/project.service";
import { Task } from "./assignement/task.service";
import Bulles from "./bulles";
import CertificationFile from "./certificationFile.service";
import Conge from "./conge.service.js";
import DataFile from "./dataFile.service";
import DataImage from "./dataImage.service";
import HolidayNameService from "./holidayName.service";
import HolidaySoldeService from "./holidaySolde.service";
import TimeBankService from "./timeBank.service";
import DayOff from "./dayOff.service";
import DocumentStorage from "./documentStorage.service";
import DocumentationFileService from "./documentation/documentation-file.service";
import Documentation from "./documentation/documentation.service";
import Emplacement from "./emplacement.service.js";
import EmployerCertification from "./emploiCertification.service.js";
import EmployerCertificationFile from "./emploiCertificationFile.service";
import EmployerCourse from "./emploiCourse.service.js";
import EmployerFormation from "./emploiFormation.service";
import EmployerNote from "./emploiNote.service.js";
import EmployerSalary from "./emploiSalaire.service.js";
import EmployerAssignment from "./employerAssignment.js";
import EmployerDocument from "./employerDocument.service.js";
import EmployerEmplacement from "./employerEmplacement.service";
import EmployerGroup from "./employerGroup.service.js";
import EmployerHours from "./employerHours.service";
import Employer from "./employerTest.service.js";
import EmployeurDService from "./employeurD/employeurD.service";
import Enterprise from "./entreprise.service.js";
import Category from "./expenses/category";
import Distance from "./expenses/distance";
import Itinerary from "./expenses/itinerary";
import Reimbursement from "./expenses/reimbursement";
import Taxes from "./expenses/taxes";
import Groupe from "./groupe.service.js";
import HoraireService from "./horaire.service";
import Material from "./material.service";
//import Mail from "./mail.service.js";
import News from "./news.service.js";
import NewsFile from "./newsFile.service.js";
import NotificationPushService from "./notification-push.service";
import Notification from "./notification.service";
import EventUnavalaibilityService from "./onboarding/eventUnavalaibility.service";
import OnboardingEmployeProtocol from "./onboarding/onboardingEmployeProtocol.service";
import OnboardingEmployeTask from "./onboarding/onboardingEmployeTask.service";
import OnboardingProtocol from "./onboarding/onboardingProtocol.service";
import OnboardingTask from "./onboarding/onboardingTask.service";
import TypeDemissionService from "./onboarding/typeDemission.service";
import TypeUnaivalabilityService from "./onboarding/typeunaivalability.service";
import UnavailabilityPlaceService from "./onboarding/unavailabilityPlace.service";
import UnavailabilityMainCauseService from "./onboarding/unavailabilityMainCause.service";
import UnavailabilityGravityService from "./onboarding/unavailabilityGravity.service";
import UnavailabilityInjuryNatureService from "./onboarding/unavailabilityInjuryNature.service";
import UnavailabilityBodyLocationService from "./onboarding/unavailabilityBodyLocation.service";
import UnavailabilityService from "./onboarding/unavailability.service";
import Password from "./password.service";
import Permission from "./permission.service.js";
import Poste from "./poste.service.js";
import SalaryAndAdvantage from "./salaryAndAdvantage.service";
import SubGroup from "./subGroup.service.js";
import TimeClock from "./timeclock.service";
import Traduction from "./traductions.service.js";
import Upload from "./upload.service.js";
import UserSetting from "./userSetting.service";
import Users from "./users.service.js";
import WorkedHoursTypes from "./workedHoursTypes.service";
import WorkingHours from "./workingHours.service";
import DayNotes from "./dayNotes.service";
import ExternalLinkService from "./externalLink.service";
import LockedDayService from "./lockedDay.service";
import PrimesServices from "./primes/primes.services";
import AccessProfileService from "./accessProfileService";
import coefficientMultiplierService from "./coefficientMultiplier.service";

export * from "./evaluation";
export * from "./developmentPlan";

export * from "./organisator";

export {
  NotificationPushService,
  Emplacement,
  EmployerCertification,
  EmployerFormation,
  EmployerCourse,
  SalaryAndAdvantage,
  EmployerNote,
  EmployerSalary,
  EmployerDocument,
  EmployerGroup,
  EmployerEmplacement,
  EmployerHours,
  Employer,
  Conge,
  // Mail,
  News,
  NewsFile,
  Permission,
  Poste,
  SubGroup,
  Traduction,
  Upload,
  Users,
  Groupe,
  Enterprise,
  Password,
  CertificationFile,
  EmployerCertificationFile,
  WorkingHours,
  DataImage,
  DataFile,
  TimeClock,
  DocumentStorage,
  HoraireService,
  Notification,
  Anniversary,
  Material,
  UserSetting,
  Assignement,
  Project,
  Task,
  EmployerAssignment,
  Formation,
  DayOff,
  Bulles,
  TypeUnaivalabilityService,
  UnavailabilityPlaceService,
  UnavailabilityMainCauseService,
  UnavailabilityGravityService,
  UnavailabilityInjuryNatureService,
  UnavailabilityBodyLocationService,
  UnavailabilityService,
  OnboardingEmployeProtocol,
  OnboardingEmployeTask,
  OnboardingProtocol,
  OnboardingTask,
  Category,
  Reimbursement,
  Itinerary,
  Distance,
  Documentation,
  DocumentationFileService,
  Taxes,
  EmployeurDService,
  EventUnavalaibilityService,
  WorkedHoursTypes,
  TypeDemissionService,
  DayNotes,
  HolidayNameService,
  HolidaySoldeService,
  TimeBankService,
  ExternalLinkService,
  LockedDayService,
  AccessProfileService,
  PrimesServices,
  coefficientMultiplierService
};
