import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from "react";

import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "@routes/IconeSvg";

import BlackTooltip from "@components/tooltip/BlackTooltip";

import { arraySearch, escapeRegExp } from "@helpers/_functions";
import { reactMemo } from "@helpers/retyped-functions";

import Badge from "./Badge";
import ResetFilter from "./ResetFilter";
import SearchInput from "./SearchInput";
import Status from "./Status";
import Switch from "./Switch";

const className = {
  normal:
    "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
  invalid:
    "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
  disabled:
    "arh-border-greyDisabledBorder arh-bg-greyDisabled arh-text-greyDisabledText"
};

/**
 * @typedef ListboxOption
 * @property {object} option
 * @property {string} optionLabel
 * @property {object} selected
 * @property {boolean=} noRadio
 * @property {string=} customWidgetName
 * @property {boolean=} recurrenceListBox
 * @property {boolean=} withTooltip
 */

/**
 * @typedef BaseListBoxProps
 * @property {string} placeholder
 * @property {string} name
 * @property {any} selectedValue
 * @property {(...args) => void?} setSelectedValue
 * @property {object[]} optionList
 * @property {boolean=} invalid
 * @property {boolean=} withSearch
 * @property {boolean=} withSwitch
 * @property {boolean=} showReset
 * @property {Object=} customStyle Style pour customiser le composant input
 * @property {string=} optionValueAttibut Par defaut _id
 * @property {string=} optionTextAttribut Par defaut name
 * @property {boolean=} readOnly
 * @property {boolean=} disabled
 * @property {boolean=} multiple
 * @property {boolean=} noRadio - Par defaut false
 * - Si true, le bouton checkbox/radio n'est pas affiché pour les éléments de la liste déroulante
 * @property {import("react-i18next").TFunction<"translation", any>=} customTranslator Fonction servant à traduire le texte affiché sur les éléments de la liste déroulante
 * @property {string=} customListHeight
 * @property {string=} customItemWidth
 * @property {boolean=} employeeList Specifique à liste employé
 * @property {string=} anotherParentClass
 * @property {string=} customButtonClass
 * @property {string=} customButtonClassProps
 * @property {string=} customWidgetName
//  * @property {boolean=} topView - Par default false
 * - Si true, le dropdown se met au dessus de l'input
 * @property {boolean=} withBadge
 * @property {string=} badgeColor
 * @property {string=} badgeText
 * @property {object=} selected Propriété en attente de refonte
 * @property {string=} otherPlaceholder
 * @property {boolean=} recurrenceListBox
 * @property {boolean=} zIndex3 - Si true, z-index = 3
 * - Si false, z-index = 20
 * @property {boolean=} withTooltip
 * @property {string=} widthDrop
 * @property {string=} statusColor
 * @property {string=} label
 * @property {string=} classLabel
 * @property {string=} classParentList Class de la div contenant le listbox en entier
 * @property {boolean=} noOpen
 * @property {import("react").ReactNode=} children
 * @property {Function=} setSuperZIndex Call this function when setting a super z-index for parent is needed
 */

/**
 * @param {BaseListBoxProps} props
 */
function BaseListBox(props) {
  const {
    // requiredValues
    placeholder,
    name,
    selectedValue,
    setSelectedValue,
    optionList = [],
    customStyle = null,
    // default options
    optionValueAttibut = "_id",
    optionTextAttribut = "name",
    invalid = false,
    readOnly = false,
    disabled = false,
    multiple = false,
    withSearch = false,
    withSwitch = false,
    showReset = false,
    noRadio = false,
    customTranslator = null,
    customListHeight = "arh-max-h-60",
    // customItemWidth = "arh-min-w-60",
    customItemWidth = "arh-w-full",
    employeeList,
    anotherParentClass,
    customButtonClass = "arh-h-11 arh-py-3",
    customButtonClassProps,
    customWidgetName = null,
    // topView,
    withBadge = false,
    badgeColor = "arh-bg-[#E6F8EF]",
    badgeText = "",
    selected = [],
    otherPlaceholder,
    recurrenceListBox = false,
    zIndex3,
    withTooltip = false,
    widthDrop,
    statusColor,
    setSuperZIndex,
  } = props;

  const { t } = useTranslation("pages.employés.listEmployés");

  // Helpers
  const translateAndShow = useCallback(
    (value) => {
      return typeof customTranslator === "function"
        ? customTranslator(value)
        : value;
    },
    [customTranslator]
  );

  function resetValue(e) {
    e.stopPropagation();
    setSelectedValue(multiple ? [] : "");
  }

  // Selection Handling
  const onSelectedChange = useCallback(
    (value) => {
      if (multiple) {
        setSelectedValue(removeDuplication(value, optionValueAttibut));
      } else {
        setSelectedValue(value);
      }
    },
    [optionValueAttibut, multiple, setSelectedValue]
  );

  const selectTextValue = useMemo(() => {
    if (multiple && selectedValue?.length) {
      return selectedValue
        .filter((item) => item._id !== "all")
        .map((s) => translateAndShow(s[optionTextAttribut]))
        .join(", ");
    }

    if (selectedValue?.[optionTextAttribut]) {
      return translateAndShow(selectedValue[optionTextAttribut]);
    }

    return "";
  }, [
    placeholder,
    selectedValue,
    multiple,
    optionTextAttribut,
    translateAndShow
  ]);

  const isOptionSelected = useCallback(
    (optionValue) => {
      if (multiple) {
        return (
          Array.isArray(selectedValue) &&
          selectedValue.some((sv) => sv[optionValueAttibut] === optionValue)
        );
      }

      return optionValue === selectedValue?.[optionValueAttibut];
    },
    [selectedValue, multiple]
  );

  const [searchValue, setSearchValue] = useState("");
  const [onSearch, setOnSearch] = useState(false);
  const filteredOptionList = useMemo(() => {
    setOnSearch(true);
    let lists = [];

    if (!Array.isArray(optionList)) {
      console.error("Listbox error: type of optionList should be an array: ", {
        optionList
      });

      lists = [];
    } else if (!withSearch || !searchValue) {
      lists = optionList;
    } else {
      const escapedSearchValue = escapeRegExp(searchValue);

      const newList = optionList.map((item) => {
        item[optionTextAttribut] = translateAndShow(item[optionTextAttribut]);
        return item;
      });

      lists = arraySearch(escapedSearchValue, newList, optionTextAttribut);
    }

    setTimeout(() => {
      setOnSearch(false);
    });

    return lists;
  }, [optionList, withSearch, searchValue, translateAndShow]);

  /**
   * @type {(props: ListboxOption) => JSX.Element}
   */
  // @ts-ignore
  const ListboxOption = React.memo(props.children);

  const isShowResetFilter =
    showReset &&
    ((multiple && selectedValue?.length) || (!multiple && selectedValue));

  const allAreSelected =
    multiple && selectedValue?.length === optionList?.length;

  const [isTopView, setIsTopView] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const dropdownHeight = 150; // Approximate height of the dropdown

      // Calculate available space above and below the element
      const spaceAbove = rect.top;
      const spaceBelow = viewportHeight - rect.bottom;

      // Determine whether to place the dropdown above or below
      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setIsTopView(true); // Place dropdown above the element
      } else {
        setIsTopView(false);
        // Place dropdown below the element
      }
    }
  };

  return (
    <Listbox
      value={selectedValue}
      onChange={onSelectedChange}
      name={name}
      disabled={readOnly}
      multiple={multiple}
    >
      {({ open }) => (
        <div
          ref={wrapperRef}
          className={props.classParentList + " arh-relative arh-w-full"}
        >
          {props?.label ? (
            <label
              className={`
                arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium 
                ${invalid
                  ? " arh-text-invalidColorTextInput "
                  : readOnly
                    ? "arh-text-greyDisabledText "
                    : "arh-text-black "
                } ${props?.classLabel}
              `}
            >
              {props?.label}
            </label>
          ) : (
            ""
          )}
          <Listbox.Button
            className={`arh-relative arh-flex arh-w-full ${props.noOpen ? "arh-pointer-events-none" : "arh-cursor-pointer"} arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-greyBorderInput focus-visible:arh-ring-2 focus-visible:arh-ring-greyBorderInput focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor disabled:arh-pointer-events-none disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText ${customButtonClass} ${customButtonClassProps} ${!disabled
              ? invalid
                ? className["invalid"]
                : className["normal"]
              : className["disabled"]
              }`}
            style={customStyle}
            onClick={handleClick}
          >
            {isShowResetFilter && <ResetFilter onClick={resetValue} />}
            {Boolean(statusColor) && (
              <Status className={`arh-mr-[10%] ${statusColor}`} />
            )}
            {customStyle ? (
              <span
                className={`arh-pointer-events-none arh-block arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-bg-transparent arh-text-xs arh-text-darkColor ${invalid ? "arh-text-invalidColorTextInput" : ""
                  }`}
              >
                {selectTextValue}
              </span>
            ) : (
              <input
                className={`arh-pointer-events-none arh-block arh-h-full arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-border-0 arh-bg-transparent arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-colorPlaceholderInputSimple disabled:arh-text-greyDisabledText ${isShowResetFilter ? "arh-ml-2.5 " : ""
                  } ${otherPlaceholder} ${invalid ? className["invalid"] : ""} ${disabled ? className["disabled"] : ""
                  }`}
                type="text"
                placeholder={translateAndShow(placeholder)}
                value={selectTextValue}
                onChange={() => { }}
                disabled={readOnly}
                style={customStyle}
              />
            )}
            {withBadge && selected.length > 0 && (
              <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-8">
                <Badge
                  bgColor={badgeColor}
                  textColor={badgeText}
                  text={selected.length}
                />
              </span>
            )}
            <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          {!props.noOpen && (
            <Transition
              as={Fragment}
              leave="arh-transition arh-ease-in arh-duration-100"
              leaveFrom="arh-opacity-100"
              leaveTo="arh-opacity-0"
            >
              <Listbox.Options
                className={`
                ${employeeList ? "arh-max-h-52" : customListHeight}
                ${isTopView ? "arh-bottom-full arh-mb-1" : "arh-mt-1"
                  } arh-absolute arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-4 arh-text-xs arh-shadow-xs ${zIndex3 ? "arh-z-[3]" : "arh-z-20"
                  // } ${customItemWidth} 3xl:arh-w-[120%] ${widthDrop ? widthDrop : "desktop-l:arh-w-full" /** bug affichage imprévu avec 3xl:arh-w-[120%] */
                  } ${customItemWidth} ${widthDrop ? widthDrop : "desktop-l:arh-w-full"
                  }
                ${anotherParentClass} ${customListHeight !== "arh-max-h-60" && "arh-overflow-y-auto"
                  }
                ${zIndex3 ? "arh-z-[3]" : "arh-z-20"}
                `}
              >
                <div className="arh-relative arh-w-full ">
                  {withSearch && (
                    <div
                      className={`${employeeList
                        ? " arh-sticky -arh-top-[1rem] arh-z-[9] arh-w-full arh-bg-white arh-py-[1rem] "
                        : " arh-sticky -arh-top-[1rem] arh-z-[9] arh-w-full arh-bg-white arh-py-[1rem] "
                        }`}
                    >
                      <SearchInput
                        placeholder={t("Rechercher")}
                        value={searchValue}
                        onChange={setSearchValue}
                        onSearch={onSearch}
                      />
                    </div>
                  )}

                  {filteredOptionList.map((option) =>
                    withTooltip ? (
                      <BlackTooltip
                        key={option[optionValueAttibut]}
                        title={translateAndShow(option[optionTextAttribut])}
                        placement="top"
                      >
                        <Listbox.Option
                          key={option[optionValueAttibut]}
                          className={`arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-truncate arh-rounded arh-text-left arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor hover:arh-bg-gray-200 active:arh-bg-greyActive`}
                          value={option}
                          disabled={readOnly}
                        >
                          <ListboxOption
                            option={option}
                            optionLabel={translateAndShow(
                              option[optionTextAttribut]
                            )}
                            selected={isOptionSelected(
                              option[optionValueAttibut]
                            )}
                            noRadio={noRadio}
                            customWidgetName={customWidgetName}
                            recurrenceListBox={recurrenceListBox}
                            withTooltip={withTooltip}
                          />
                        </Listbox.Option>
                      </BlackTooltip>
                    ) : (
                      <Listbox.Option
                        key={option[optionValueAttibut]}
                        className={`arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-truncate arh-rounded arh-text-left arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor hover:arh-bg-gray-200 active:arh-bg-greyActive`}
                        value={option}
                        disabled={readOnly}
                      >
                        <ListboxOption
                          option={option}
                          optionLabel={translateAndShow(
                            option[optionTextAttribut]
                          )}
                          selected={isOptionSelected(
                            option[optionValueAttibut]
                          )}
                          noRadio={noRadio}
                          customWidgetName={customWidgetName}
                          recurrenceListBox={recurrenceListBox}
                        />
                      </Listbox.Option>
                    )
                  )}

                  {withSwitch && (
                    <>
                      <hr />
                      <div className="arh-my-2  arh-flex  arh-justify-center">
                        <div className="arh-flex arh-items-center arh-space-x-4 arh-font-poppins arh-font-normal arh-text-darkColor">
                          <label className="!arh-mb-0 arh-text-xs">
                            {t("Tout sélectioner")}
                          </label>
                          <Switch
                            switchDouble={true}
                            color={
                              allAreSelected
                                ? "arh-bg-[#1A4E63]"
                                : "arh-bg-[#D1D7D5]"
                            }
                            checked={!allAreSelected}
                            handleChange={() =>
                              setSelectedValue(allAreSelected ? [] : optionList)
                            }
                          />
                          <label className="!arh-mb-0 arh-text-xs">
                            {t("Tout désélectionner")}
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Listbox.Options>
            </Transition>
          )}
          <CallForSetSuperZIndex
            open={open}
            setSuperZIndex={setSuperZIndex}
          />
        </div>
      )}
    </Listbox>
  );
}

function CallForSetSuperZIndex({ open, setSuperZIndex }) {
  useEffect(() => {
    if (typeof setSuperZIndex === "function") {
      setSuperZIndex("arh-relative arh-z-[4]", open);
    }
  }, [open]);

  return <div></div>;
}

const findDuplication = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) != index);

function removeDuplication(list, valueAttribut) {
  const duplications = findDuplication(list.map((item) => item[valueAttribut]));
  return list.filter((item) => !duplications.includes(item[valueAttribut]));
}

export default reactMemo(BaseListBox);
