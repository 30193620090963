import BaseService from "./base.service";
import http from "../http-common";

class HolidaySoldeService extends BaseService {
  constructor() {
    super("holiday-solde");
  }

  getByEmployee(employeId) {
    return http.get(`${this.path}/list/${employeId}`);
  }

  upsert(data) {
    return http.post(`${this.path}/upsert`, data);
  }
}

export default new HolidaySoldeService();