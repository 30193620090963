import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import useOutsideCallBack from "@hooks/useOutsideCallBack";
import Loading from "@image/gif/loading.gif";
import Loupe from "@image/icones/employer/loup.svg";
import { BLANK_PROFILE_PICTURE } from "@helpers/_constants";
import { diacriticSensitiveRegex, escapeRegExp } from "@helpers/_functions";
import Avatar from "./Avatar";
import CheckboxSimple from "./CheckboxSimple";

/**
 * @typedef {object} Props
 * @property {Array} employeList
 * @property {string=} placeholder
 * @property {string=} classInput
 * @property {Object=} value
 * @property {function(string): void} onChange
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export function SearchEmployeInput(props) {
  const {
    employeList,
    placeholder,
    classInput,
    value: selectedEmploye,
    onChange: setSelectedEmploye
  } = props;

  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { wrapperRef } = useOutsideCallBack(() => {
    setSearchValue(selectedEmploye?.fullName || "");
    setIsSearching(false);
  });

  useEffect(() => {
    setSearchValue(selectedEmploye?.fullName || "");
    setIsSearching(false);
  }, [selectedEmploye]);

  const filteredEmployeList = useMemo(() => {
    if (!Array.isArray(employeList)) {
      console.error("Listbox error: type of lists should be an array: ", {
        employeList
      });
      return [];
    }

    if (!searchValue) {
      return employeList;
    }

    const searchRegex = new RegExp(
      diacriticSensitiveRegex(escapeRegExp(searchValue.toUpperCase()))
    );

    return employeList.filter((employe) =>
      searchRegex.test(employe.fullName.toUpperCase())
    );
  }, [employeList, searchValue]);

  const isSelected = useCallback(
    (employe) => {
      return employe.id === selectedEmploye?.id;
    },
    [selectedEmploye]
  );

  return (
    <Listbox
      value={selectedEmploye}
      onChange={setSelectedEmploye}
      name="searchEmployeInput"
    >
      <div
        className=" arh-relative arh-w-full"
        ref={wrapperRef}
      >
        <Listbox.Button
          className="arh-relative arh-cursor-pointer arh-rounded arh-text-left arh-border-0 arh-border-solid arh-font-poppins arh-font-normal arh-text-xs arh- focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor arh-bg-white placeholder:arh-text-[#8B8F91] arh-border-[#ECECEC]"
          as="div"
        >
          <div className="arh-relative">
            <input
              type="text"
              className={
                classInput +
                " arh-p-[10px] arh-w-full arh-bg-transparent arh-border arh-border-solid arh-border-[#ECECEC] arh-rounded arh-pl-[10px] arh-pr-8 arh-py-[7.5px] arh-font-poppins arh-font-normal arh-text-darkColor arh-text-xs placeholder:arh-text-[#8B8F91]"
              }
              placeholder={placeholder}
              onClick={() => setIsSearching(true)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <img
              src={isSearching ? Loading : Loupe}
              className="arh-absolute arh-top-1/2 -arh-translate-y-1/2 arh-right-0 arh-pr-[10px] arh-w-7"
            />
          </div>
        </Listbox.Button>
        {isSearching && (
          <Listbox.Options
            static
            className="arh-max-h-60 arh-absolute arh-mt-1  arh-w-full arh-overflow-y-auto  arh-overflow-x-hidden arh-rounded arh-bg-white arh-py-[0.938rem] arh-px-4 arh-text-xs arh-shadow-xs arh-z-[6] desktop-l:arh-w-[150%] arh-right-0"
          >
            {filteredEmployeList.map((employe) => (
              <Listbox.Option
                key={employe.id}
                className="arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-font-poppins arh-font-normal arh-text-xs arh-text-darkColor arh-truncate arh-py-1.5"
                value={employe}
              >
                <CheckboxSimple
                  id={employe.id}
                  checked={isSelected(employe)}
                  noRadio
                  label={
                    <Avatar
                      src={employe.imgEmploye || BLANK_PROFILE_PICTURE}
                      name={employe.fullName}
                      textStyle="arh-font-poppins arh-font-normal arh-text-xs arh-text-black"
                    />
                  }
                />
              </Listbox.Option>
            ))}
          </Listbox.Options>
        )}
      </div>
    </Listbox>
  );
}
