/*
 * Status is a little dot indication component
 * Color { green : "#92E890", yellow : "#FFDF6B", orange: "#FD7D54" }
 */

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {string=} props.color
 */
import React from "react";

function Status({ className, color }) {
  return (
    <div
      className={`arh-shrink-0 arh-block !arh-w-1.5 !arh-h-1.5 arh-rounded-full ${className}`}
      style={{ backgroundColor: color }}
    ></div>
  );
}

export default Status;
